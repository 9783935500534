.Typewriter {
  font-size: 6vmin;
  font-weight: 100;
  white-space: break-spaces;
  position: absolute;
  width: 100vw;
  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  &__wrapper {
    min-width: 48vw;
    display: flex;
    height: calc(95vh - 50%);
    margin-left: 40rem;
    @media screen and (max-width: 992px) {
      margin: 0;
    }
  }
}
