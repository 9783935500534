footer {
  @extend .center;
  height: 5vh;
  // text-shadow: 0px 0px 12px black;
  // background-image: linear-gradient(
  //   to top,
  //   rgba(17, 19, 22, 0.5),
  //   rgba(255, 0, 0, 0)
  // );
  background-color: #333;
  color: white;
  font-size: 12px;
  z-index: 5;
}
