.muteButton {
  transition: all 0.4s;
  position: absolute;
  bottom: 2.3rem;
  left: 3rem;
  width: 3.1rem;
  height: 3.1rem;
  border: 0;
  border-radius: 50%;
  outline: none;
  z-index: 15;
  opacity: 0.4;
  box-shadow: 0 16px 32px 0 rgba(0, 31, 109, 0.08);
  @media screen and (max-width: 1200px) {
    bottom: 1.6rem;
  }
  &:hover {
    opacity: 1;
  }
  img {
    margin-bottom: 0.2rem;
    width: 1rem;
  }
  padding-top: 0.1rem;
}
