.clients {
  transition: 0.2s ease-out;
  flex-direction: row;
  flex-wrap: wrap;
  > div {
    transition: 0.2s ease-out;
    transform: scale(1);
    &:hover {
      transform: scale(1.2) !important;
    }
  }
}

.logos {
  background-image: url("../images/bg_j.png");
  background-size: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.4rem;
  margin: 1.2rem;
  transform: scale(1);
  overflow: hidden;
  transition: 0.2s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    display: inline;
    transform: rotate(15deg);
    top: -1rem;
    left: -7rem;
    width: 91px;
    height: 120%;
    opacity: 0.1;
    background: linear-gradient(-90deg, white, transparent);
  }

  &:hover::after {
    animation: wide 0.6s ease-out;
    animation-fill-mode: forwards;
  }
}
