@keyframes wide {
  from {
    left: -5rem;
    transform: scale(1);
  }
  to {
    left: 10rem;
    transform: scale(1.2);
  }
}

@keyframes slide {
  from {
    width: 100vw;
  }
  to {
    width: 0vw;
  }
}
