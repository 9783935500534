.cursor {
  cursor: pointer !important;
}

.inputBank {
  input {
    @media screen and (min-width: 768px) {
      font-size: 20px !important;
    }
  }
  > div:first-child {
    margin-bottom: 2rem;
  }
}

.payment-images {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  img {
    width: 12rem;
  }
}
