.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.after {
  transition: all 0.2s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: "";
  color: transparent;
  background: #fff;
  height: 0.1rem;
}

nav {
  position: fixed;
  @extend .center;
  width: 100%;
  align-items: flex-start;
  height: 20vh;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba($dark, 0.8));
  z-index: 1;
  .navContainer {
    @extend .center;
    margin-top: 2rem;
    justify-content: space-between;
    width: 80%;
    position: relative;
    .logo {
      @extend .center;
      img {
        height: 35px;
      }
    }
    img.toggle {
      transition: ease-in-out 0.2s;
      display: none;
      width: 1.2rem;
      z-index: 10;
      cursor: pointer;

      @media screen and (max-width: 1300px) {
        position: fixed;
        top: 2.6rem;
        right: 3rem;
        display: flex;
      }
    }
    .menu {
      &.opened {
        transform: translateX(0%);
      }

      @extend .center;
      transition: ease-in-out 0.2s;
      @media screen and (max-width: 1300px) {
        display: flex;
        flex-direction: column;
        position: fixed;
        right: 0;
        top: 0;
        background-color: rgba($dark, 0.95);
        height: 100vh;
        width: 350px;
        justify-content: space-around;
        transform: translateX(100%);
      }
      @media screen and (max-width: 420px) {
        display: flex;
        flex-direction: column;
        position: fixed;
        background-color: rgba($dark, 0.95);
        width: 100%;
        justify-content: space-around;
        transform: translateX(100%);

        &.opened {
          transform: translateX(0%);
        }
      }
      .social {
        svg {
          width: 1.4rem;
          &:hover {
            fill: $primary;
          }
        }
        p:hover {
          color: $primary;
        }
        > a {
          transition: 0.2s ease-in-out;
          &:not(:first-child) {
            margin-left: 0.8rem;
          }
          &:hover {
            transform: rotate(15deg);
          }
        }
      }
      ul {
        padding: 0;
        margin: 0.3rem 3rem 0;
        display: flex;
        list-style-type: none;
        height: 100%;
        @media screen and (max-width: 1300px) {
          display: block;
          height: auto;
        }
        li {
          @extend .center;
          padding: 0 12px;
          font-size: 17px;
          border-bottom: transparent solid 0.3rem;
          overflow: hidden;
          white-space: nowrap;
          height: 3.2rem;
          a {
            font-weight: 500;
            color: #fff;
            text-decoration: none;
            display: flex;
            align-items: center;
            height: 70%;
            position: relative;
            @media screen and (max-width: 1300px) {
              font-size: 20px;
              font-weight: 300;
            }
            &:after {
              @extend .after;
            }
            &:hover {
              color: $primary;
              &:after {
                width: 100%;
              }
            }
            &.active {
              color: $primary;
              &:after {
                @extend .after;
                background: $primary !important;
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
  .languages {
    @media screen and (min-width: 1300px) {
      margin-right: 30px;
      margin-left: 8px;
    }
  }
}
